





import { Component, Vue, Prop, Ref } from 'vue-property-decorator'
import DrillTitle from '@/components/modules/drillsv3/atoms/Title.vue'
import DrillPenMath from '@/components/organisms/study/DrillPenMath.vue'

@Component({ components: { DrillTitle, DrillPenMath } })
export default class TitleWithPenSwitcher extends Vue {

  @Ref() drillPenMathRef!: DrillPenMath

  @Ref() contentRef!: HTMLElement

  @Prop({ default: false })
  isHiddenDrillPen!: boolean

  @Prop()
  onUndo!: Function

  @Prop()
  onRedo!: Function

  @Prop()
  arrHiddenColor!: any

  @Prop()
  onChangePen?: Function

  @Prop()
  onTrash?: Function

  @Prop()
  onBookmark?: Function

  @Prop()
  onChangeHand?: Function

  @Prop({
    default: {
      trashMemo: false,
      trashAnswer: false,
      bookmark: true
    }
  })
  hiddenUiDrillPen?: {
    trashMemo: boolean,
    trashAnswer: boolean,
    bookmark: boolean
  }

  @Prop()
  onChangeLineWidth?: Function

  @Prop()
  onErase?: Function


  private is_random_question = false
  private isScrollOver = false
  public async mounted(): Promise<void> {
    this.is_random_question = this.$store.getters['drillsV3/modeActive'].is_random_question
    window.addEventListener('scroll', this.handleScroll);
  }

  public onSetActiveColor(colorCode: string) {
    this.drillPenMathRef.onSetActiveColor(colorCode)
  }

  private handleScroll() {
    if (window.scrollY > 75) {
      this.isScrollOver = true
    } else {
      this.isScrollOver = false
    }
  };

  private get hiddenUiDrillPenComputed() {
    return {
      trashMemo: false,
      trashAnswer: false,
      bookmark: true,
      ...(this.hiddenUiDrillPen || {})
    }
  }

  private beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  }
}
