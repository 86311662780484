





















import PenCanvasMath from '@/components/modules/drills/atoms/PenCanvasMath.vue'
import ScrollGuide from '@/components/modules/drills/atoms/ScrollGuide.vue'
import TooltipButton from '@/components/modules/drills/atoms/TooltipButton.vue'
import { Component, Prop, Vue, Mixins } from 'vue-property-decorator'
import { StrokeType } from '@/types/canvas'
import { Debounce } from 'vue-debounce-decorator'
import CanvasMethod from '@/mixins/v3/canvas'

@Component({
  components: {
    ScrollGuide,
    TooltipButton,
    PenCanvasMath
  },
})
export default class QuestionImageHorizontalV3 extends Mixins(CanvasMethod) {
  @Prop({ default: { title: '', url: [], questionCode: '', urlD: [] } })
  dataI?: { title: string; url: string[]; questionCode: string; urlD: string[] }

  @Prop()
  sijiUrl?: string

  @Prop()
  answerUrl?: string[]

  @Prop({ default: false })
  isHiddenQuestion?: boolean

  @Prop({ default: false })
  isHiddenAnswer?: boolean

  @Prop()
  onDragCanvasEnd?: Function

  @Prop()
  onDragCanvasStart?: Function

  @Prop()
  height?: string

  private rationOfQuestion = 0

  private get checkDisplaySji() {
    return this.sijiUrl?.length
  }

  private get checkDisplayAnswer() {
    return this.answerUrl?.length
  }
  private get checkDisplayDFrame() {
    return !!this.dataI?.urlD?.length
  }

  private get imageQuestions() {
    return this.dataI?.url
  }

  private get imagesD() {
    return this.dataI?.urlD
  }

  private get checkSubjectCodeKo() {
    return `questions__frameContent ${this.checkDisplayDFrame ? 'questions__frameContent--vertical' : ''}`
  }

  private handleResize = this.changeCanvasSize

  private created() {
    this.canvasName = 'canvasNameMemo';
  }

  private mounted() {
    this.handleResize(true)
    window.addEventListener('resize', () => this.handleResize(false))
  }

  private beforeDestroy() {
    window.removeEventListener('resize', () => this.handleResize(false))
  }


  private handleDragEndCanvas(stroke: StrokeType[]) {
    this.onDragCanvasEnd?.(stroke)
  }

  private handleDragStartCanvas() {
    this.onDragCanvasStart?.()
  }

  @Debounce(300)
  private changeCanvasSize(isMount?: boolean): void {
    const leftBlock = this.$refs['questionsFrameRef'] as HTMLElement
    // canvasサイズ設定
    const { width, height } = this.calculateCanvasSize(leftBlock)

    const oldWidthHeight = Vue.prototype.$penCanvases[this.canvasName].getWidthHeight()

    const oldStrokes = Vue.prototype.$penCanvases[this.canvasName].getStroke()

    const scaleX = width / oldWidthHeight.width
    const scaleY = (width / this.rationOfQuestion) / (oldWidthHeight.width / this.rationOfQuestion)
    const strokes = oldStrokes.map((stroke: any) =>
      stroke.map((point: any) => ({
        ...point,
        x: point.x * scaleX,
        y: point.y * scaleY,
      }))
    )

    this.setCanvasSize(Vue.prototype.$penCanvases[this.canvasName], { width, height })

    setTimeout(() => {
      Vue.prototype.$penCanvases[this.canvasName].redraw(strokes)
    })
  }

  private calculateCanvasSize(element: HTMLElement): any {
    if (!element) return { width: 0, height: 0 }
    return { width: element.clientWidth, height: element.scrollHeight }
  }

  private handleLoadImage(event: any) {
    const img = event.target
    const ratio = img.width / img.height

    console.log({ img: img.width, height: img.height });

    this.rationOfQuestion = ratio
    // Vue.prototype.$penCanvases[this.canvasName].context.drawImage(img, 0, 0, img.width, img.height);
  }
}
