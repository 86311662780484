












import { Component, Mixins, Vue, Prop } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import DrillWebApi from '@/mixins/drillsv3/WebApi'
import DrillTransition from '@/mixins/drillsv3/DrillTransition'
import { DRILL_TYPE, PAGE_TYPE } from '@/store/modules/DrillsV3'
import DrillCookies from '@/mixins/drillsv3/DrillCookies'
import QuestionMixin from '@/components/modules/drillsv3/molecules/QuestionMixin'

type Input = {
  s_problem_id: string
  questions: Question[]
}
type Question = {
  question_code: string
  answerFormatCode: string
  answer: any
  correct?: boolean
}

@Component({
  components: {
    ButtonBase,
  },
})
export default class Transition extends Mixins(DrillWebApi, DrillTransition, DrillCookies, QuestionMixin) {
  private get classModeCode() {
    return this.$store.getters['drillsV3/classModeCode']
  }

  private get classCategoryCode() {
    return this.$store.getters['drillsV3/classCategoryCode']
  }

  private get subjectCode() {
    return this.$store.getters['drillsV3/subjectCode']
  }

  @Prop({ default: null })
  getPageInputV3!: Function

  @Prop({ default: { text: '', onClick: undefined, class: '' } })
  buttonSubmitProps!: { text: string; onClick: () => void; class: string }

  @Prop({ default: { text: '', onClick: undefined, class: '' } })
  buttonCancelProps!: { text: string; onClick: (completeFunc?: () => Promise<string>) => void; class: string }
  /**
   * 左側(前へ)ボタンのテキスト
   */
  private get prevText(): string {
    let text = ''

    switch (this.pageType) {
      case PAGE_TYPE.DRILL:
      case PAGE_TYPE.SCORING_UNIT:
      case PAGE_TYPE.RESULT_UNIT:
        text = '中断する'
        break
    }

    return text
  }

  /**
   * 左側(前へ)ボタン押下時の遷移先
   */
  private get prevUrl(): string {
    let url = ''

    switch (this.pageType) {
      case PAGE_TYPE.DRILL:
      case PAGE_TYPE.SCORING_UNIT:
        if (this.classModeCode !== ('MN' || 'NY')) {
          // AI学習の場合、演習結果画面へ移行する
          url = this.urlResultAll
        } else {
          // 授業モードごとの一覧画面に遷移
          url = this.urlCurriculumList(this.classModeCode, this.classCategoryCode)
        }
        break
      case PAGE_TYPE.RESULT_UNIT:
        // 設問ごとの結果画面から中断した場合、演習の結果画面を一度挟む
        url = this.urlResultAll
        break
    }

    return url
  }

  /**
   * 右側(次へ)ボタンのテキスト
   */
  private get nextText(): string {
    let text = ''

    switch (this.pageType) {
      case PAGE_TYPE.DRILL:
        text = '答え合わせをする'
        break
      case PAGE_TYPE.SCORING_UNIT:
        text = '結果を登録する'
        break
      case PAGE_TYPE.RESULT_UNIT:
        if (this.$store.getters['drills/endCondition'].left == 1) {
          text = '結果一覧へ'
        } else {
          text = '次の問題へ'
        }
        break
      case PAGE_TYPE.RESULT_ALL:
        // switch (this.classModeCode) {
        //   case 'AI':
        //     text = '学習内容選択へ戻る'
        //     break
        //   default:
        //     text = '項目リストへ戻る'
        //     break
        // }
        if (this.classModeCode !== ('MN' || 'NY')) {
          text = '学習内容選択へ戻る'
        } else {
          text = '項目リストへ戻る'
        }
        break
    }

    return text
  }

  /**
   * 右側(次へ)ボタン押下時の遷移先
   */
  private get nextUrl(): string {
    let url = ''
    switch (this.pageType) {
      case PAGE_TYPE.DRILL:
        url = this.urlScoring
        break
      case PAGE_TYPE.SCORING_UNIT:
        url = this.afterScoringUnit
        break
      case PAGE_TYPE.RESULT_UNIT: {
        const drillType = this.$route.query?.drillType as string

        if (!drillType) {
          url = this.urlDrill;
          break;
        }
        if (drillType === DRILL_TYPE.DRILL_BOOKMARK) {
          url = `/student/v3/bookmark/detail/${this.$route.query?.curriculumSUnitId}/${this.$route.query?.questionCode}`
        } else if (drillType === DRILL_TYPE.DRILL_CHALLENGE) {
          url = '/student/v3/challenge'
        }
        break
      }

      case PAGE_TYPE.RESULT_ALL: {
        const drillType = this.$route.query?.drillType as string

        if (!drillType) {
          url = this.urlCurriculumList(this.classModeCode, this.classCategoryCode)
          break
        }
        if (drillType === DRILL_TYPE.DRILL_BOOKMARK) {
          url = `/student/v3/bookmark/detail/${this.$route.query?.curriculumSUnitId}/${this.$route.query?.questionCode}`
        } else if (drillType === DRILL_TYPE.DRILL_CHALLENGE) {
          url = '/student/v3/challenge'
        } else if (drillType === DRILL_TYPE.DRILL_CUSTOM) {
          url = '/student/v3/dashboard'
        }
        break
      }

    }
    return url
  }

  /**
   * 採点画面後の遷移先URL取得
   */
  private get afterScoringUnit(): string {
    let url = ''
    // switch (this.classModeCode) {
    //   case 'MN':
    //     // 間違い直しの場合は問題ごとの結果画面を出さないため次の問題へ
    //     url = this.urlDrill
    //     break
    //   case 'AI':
    //     // AI学習の場合は問題ごとの結果画面へ
    //     url = this.urlResultUnit
    // }
    if (this.classModeCode == 'MN') {
      url = this.urlDrill
    } else {
      url = this.urlResultUnit
    }
    return url
  }

  private get returnTextButtonSubmit() {
    if (this.buttonSubmitProps.text && this.buttonSubmitProps.text.length) return this.buttonSubmitProps.text

    return this.nextText
  }

  private get returnTextButtonCancel() {
    if (this.buttonCancelProps.text && this.buttonCancelProps.text.length) return this.buttonCancelProps.text

    return this.prevText
  }

  private get returnPropsButtonSubmit() {
    return this.buttonSubmitProps
  }

  protected mounted(): void {
    Vue.prototype.$logger.info('-- Transition mounted')
  }

  /**
   * 前ページへ遷移
   */
  private async completePrevPage() {
    let prevUrl = this.prevUrl
    if (this.classModeCode !== ('MN' || 'NY') && this.classCategoryCode !== 'SY') {
      // ドリル終了処理
      const status = await this.completeDrillApi(this.resultDrillId)
      if (this.statusDeleted(status)) {
        // ドリルが削除されていた場合、遷移先を一覧画面に変更
        prevUrl = this.urlCurriculumList(this.classModeCode, this.classCategoryCode)
      }
    }
    return prevUrl
  }

  private async prevPage() {
    if (this.buttonCancelProps.onClick) {
      this.buttonCancelProps.onClick(this.completePrevPage)
      return
    }
    Vue.prototype.$logger.info('-- Transition prevPage')

    Vue.prototype.$loading.start()

    // 規定の遷移先ページ取得
    let prevUrl = this.prevUrl

    // AI学習モードの場合、中断時にドリル終了処理を実行する

    // ドリルが削除されていた場合、遷移先を一覧画面に変更
    prevUrl = await this.completePrevPage()

    if (this.classCategoryCode == 'SY') {
      // ドリルが削除されていた場合、遷移先を一覧画面に変更
      prevUrl = this.urlCurriculumList(this.classModeCode, this.classCategoryCode)
    }

    Vue.prototype.$loading.complete()

    const drillType = this.$route.query?.drillType as string

    if (!drillType) {
      this.$router.push({
        path: prevUrl,
      })
      return;
    }


    if (drillType === DRILL_TYPE.DRILL_BOOKMARK) {
      prevUrl = `/student/v3/bookmark/detail/${this.$route.query?.curriculumSUnitId}/${this.$route.query?.questionCode}`
    } else if (drillType === DRILL_TYPE.DRILL_CHALLENGE) {
      prevUrl = '/student/v3/challenge'
    } else if (drillType === DRILL_TYPE.DRILL_CUSTOM) {
      prevUrl = '/student/v3/dashboard'
    }
    // 画面遷移
    this.$router.push({
      path: prevUrl,
    })
  }

  /**
   * 次ページへ遷移
   */
  private async nextPage() {
    if (this.buttonSubmitProps.onClick) {
      this.buttonSubmitProps.onClick()
      return
    }
    Vue.prototype.$logger.info('-- Transition nextPage')

    Vue.prototype.$loading.start()

    switch (this.pageType) {
      case PAGE_TYPE.DRILL:
        if (this.getPageInputV3) {
          // 次ページへの引き渡し用cookie
          await this.setCurrentPageInput(this.getPageInputV3())
          // 全ページ分の保持cookie
          await this.setDrillInputCookie(
            this.$store.getters['drillsV3/currentPage'],
            Number(this.$route.query.resultDrillId),
            this.getPageInputV3()
          )
        }
        break
      case PAGE_TYPE.SCORING_UNIT:
        // 該当情報がなければ終

        if (!(await this.scoringUnit())) {
          // 入力に問題がある場合は終了
          return
        }
        //AutoScore
        // if (!(await this.formatInput())) {
        //   // 入力に問題がある場合は終了
        //   return
        // }
        break
      case PAGE_TYPE.RESULT_ALL:
        // 完了画面
        // 教科コードを更新
        await this.setSubjectCode()
        break
    }

    Vue.prototype.$loading.complete()

    // 画面遷移
    this.$router.push({
      path: this.nextUrl,
    })
  }

  private async formatInput(): Promise<boolean> {
    const cookie = await this.getDrillInputCookie(Number(this.$route.query.resultDrillId))
    const storedInput = cookie[Number(this.$route.query.page)]
    // 該当情報がなければ終了
    if (!storedInput) {
      return false
    }
    let checkSelfScoring = true
    const sProblemIds = this.$store.getters['drillsV3/allProblems']
    const questionInput = []
    const listQuestion = []
    for (let i = 0; i < storedInput.length; i++) {
      const q = this.question(i)
      if (q) {
        q.setSafeInputValue(storedInput[i])
        const question: Question = {
          question_code: q.questionCode,
          answerFormatCode: q.answerFormatCode,
          answer: q.inputValue,
        }
        if (q.answerFormatCode == 'SELF_SCORING') {
          if (storedInput[i] === '') {
            checkSelfScoring = false
          } else {
            checkSelfScoring = true
            question.correct = storedInput[i]
          }
        }
        listQuestion.push(question)
      }
    }
    if (checkSelfScoring == false) {
      alert('全ての問題で○×をつけましょう！')
      Vue.prototype.$loading.complete()
      return false
    }
    let countQuestion = 0
    for (let i = 0; i < sProblemIds[0]['sProblemIds'].length; i++) {
      const input: Input = {
        s_problem_id: sProblemIds[0]['sProblemIds'][i],
        questions: [],
      }
      const questionInSproblem = sProblemIds[0]['questionNumber'][i]
      input.questions = listQuestion.slice(countQuestion, countQuestion + questionInSproblem)
      countQuestion = questionInSproblem + countQuestion
      questionInput.push(input)
    }
    // 現在ページ取得
    await this.registCorrectUnitsV3(this.resultDrillId, Number(this.$route.query.page), questionInput)
    return true
  }

  /**
   * 教科コードのcookieセット
   */
  private async setSubjectCode() {
    const cookie = Vue.prototype.$cookies.get('dataGdls')
    cookie['subjectCode'] = this.subjectCode
    await Vue.prototype.$cookies.set('dataGdls', cookie, Function(`return (${process.env.VUE_APP_COOKIE_EXPIRE})`)())
  }

  /**
   * 自己採点実施(大問単位)
   *  入力に問題がある場合はfalseを返す
   *
   * @returns 入力チェック結果
   */
  private async scoringUnit(): Promise<boolean> {
    const correctInput = this.$store.getters['drillsV3/corrects']
    // 入力内容チェック
    const correctCheck = correctInput.flat()
    if (correctCheck.includes(null)) {
      alert('全ての問題で○×をつけましょう！')
      Vue.prototype.$loading.complete()
      return false
    }

    // 現在ページ取得
    const page = Number(this.$route.query.page)

    // 結果登録APIを呼ぶ
    await this.registCorrectUnits(this.resultDrillId, page, correctInput[0])
    return true
  }

  private get enableNext(): boolean {
    let enable = true
    // ページタイプによって次へボタンの動作可否を制御
    switch (this.pageType) {
      case PAGE_TYPE.DRILL:
        enable = this.$store.getters['activeDrill/enableNext'] || false
        break
    }
    return enable
  }
}
