import { Component, Vue } from 'vue-property-decorator'
import { S3PathType } from '@/models/api/s3PathType'
import { ImagePaths } from '@/mixins/drillsv3/WebApi'
import { Problem } from '@/store/modules/DrillsV3'

@Component
export default class ImageAbstractMixin extends Vue {
  /**
   * Web APIを通して取得した問題情報を取得
   *
   * @returns 問題情報配列
   */
  private get allProblems(): Problem[] {
    return this.$store.getters['drillsV3/allProblems']
  }

  /**
   * 大問数を取得
   *
   * @returns 問題情報
   */
  protected get problemLength(): number {
    return this.allProblems.length
  }

  /**
   * ページ番号取得
   */
  protected get currentPageNumber(): number {
    return this.$store.getters['drillsV3/currentPage']
  }

  /**
   * Web APIを通して取得した問題情報を取得
   *
   * @param pageIndex ページindex
   * @returns 問題情報
   */
  private problem(pageIndex: number): Problem {
    return this.allProblems[pageIndex]
  }

  /**
   * Web APIを通して取得した画像URLを取得
   *
   * @param pageIndex ページindex
   * @returns 画像URL群
   */
  private imagePaths(pageIndex: number): ImagePaths {
    return this.problem(pageIndex).imagePaths
  }

  /**
   * 読解文の画像URLを取得
   *
   * @param pageIndex ページindex
   * @returns 読解文の画像URLの配列
   */
  protected dokkai(pageIndex: number): string[] {
    return this.imagePaths(pageIndex).d || []
  }

  /**
   * 指示文の画像URLを取得
   *
   * @param pageIndex ページindex
   * @returns 指示文の画像URLの文字列。指示文の場合は1つのみ
   */
  protected siji(pageIndex: number): string | null {
    return this.imagePaths(pageIndex).j || null
  }

  protected textExplainS(pageIndex: number, questionIndex: number): any {
    return this.problem(pageIndex).imageExplainUrls.flat()[questionIndex]?.images.s || []
  }
  protected allTextExplainS(pageIndex: number): any {
    return this.imagePaths(pageIndex).s || []
  }

  /**
   * 問題文の画像URLを取得
   *
   * @param pageIndex ページindex
   * @returns 問題文の画像URLの配列
   */
  protected mondai(pageIndex: number): string[] {
    return this.imagePaths(pageIndex).m || []
  }

  /**
   * 解答文の画像URLを取得
   *
   * @param pageIndex ページ
   * @returns 解答文の画像URLの配列
   */
  protected kaitou(pageIndex: number): string[] {
    return this.imagePaths(pageIndex).t || []
  }

  /**
   * 問題が大問かどうか取得
   *
   * @param pageIndex ページindex
   * @returns 大問かどうかの真偽値
   */
  protected isLProblem(pageIndex: number): boolean {
    return this.problem(pageIndex).isLProblem
  }

  /**
   * 問題が小問かどうか取得
   *
   * @param pageIndex ページindex
   * @returns 小問かどうかの真偽値
   */
  private isSProblem(pageIndex: number): boolean {
    return !this.isLProblem(pageIndex)
  }

  /**
   * 小問画像が一つ目かどうか
   *
   * @param pageIndex ページindex
   * @param s3PathType 画像タイプ
   * @param imageIndex 問題画像のindex
   * @returns 小問かどうかの真偽値
   */
  protected isSProblemFirstImage(pageIndex: number, s3PathType: S3PathType, imageIndex: number): boolean {
    // 小問に複数画像(***m02.png, ***m03.png)がある場合はfalse
    const regexp = new RegExp(s3PathType + '0[2-9].png$')
    // 記述上、処理中に書き変わる可能性があるため変数に保持
    const imagePath = this.imagePaths(pageIndex)
    const filename = imagePath[s3PathType]?.[imageIndex] || ''

    return this.isSProblem(pageIndex) && !regexp.test(filename)
  }

  /**
   * 小問ID
   *
   * @param pageIndex ページindex
   * @returns 小問ID
   */
  protected sProblemIds(pageIndex: number): number[] {
    return this.problem(pageIndex).sProblemIds
  }

  /**
   * 小問コード
   *
   * @param pageIndex ページindex
   * @returns 小問コード
   */
  protected sProblemCodes(pageIndex: number): string[] {
    return this.problem(pageIndex).sProblemCodes
  }

  /**
   * 読み込みが完了しているか否か
   *
   * @returns 読み込み状況
   */
  protected get processed(): boolean {
    return this.$store.getters['drillsV3/processed']
  }

  /**
   * 大問の設問数
   *
   * @param pageIndex ページindex
   * @returns 設問数
   */
  protected totalNumber(pageIndex: number): number {
    return this.problem(pageIndex).totalNumber
  }

  /**
   * 小問の設問数
   *
   * @param pageIndex ページindex
   * @returns 設問数
   */
  protected questionNumber(pageIndex: number): number[] {
    return this.problem(pageIndex).questionNumber
  }

  /**
   * 設問番号の開始番号(通番)
   *
   * @param pageIndex ページindex
   * @param index 問題インデックス
   * @returns 問題番号
   */
  protected pageQuestionStartNumber(pageIndex: number, index: number): number {
    return (
      this.questionNumber(pageIndex)
        .slice(0, index)
        .reduce((sum, element) => sum + element, 0) + 1
    )
  }

  /**
   * 問題インデックスからproblemのインデックスを算出する
   *
   * @param pageIndex ページインデックス
   * @param questionIndex 問題インデックス
   * @returns 大問インデックス
   */
  protected problemIndexFromQuestionIndex(pageIndex: number, questionIndex: number): number {
    const questionNumbers = this.questionNumber(pageIndex)

    // 問題数を基準に、何番目のproblemかを判定する
    let questionCount = 0
    const index = questionNumbers.findIndex((questionNumber) => {
      questionCount += questionNumber
      return questionCount > questionIndex
    })

    return index
  }

  /**
   * 解説動画が存在するか
   *
   * @param pageIndex ページindex
   * @param questionIndex 問題インデックス
   * @returns 解説動画の有無
   */
  protected existMovie(pageIndex: number, questionIndex: number): boolean {
    const problemIndex = this.problemIndexFromQuestionIndex(pageIndex, questionIndex)
    return this.problem(pageIndex).existMovies[problemIndex]
  }
  protected isPageExistMovie(pageIndex: number): boolean {
    return this.problem(pageIndex).existMovies.some((value ) => value)
  }
}
