





import { TITLE_HOMEWORK } from '@/constants'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Title extends Vue {
  private title = ''
  private classCategoryCode = ''
  private is_random_question = false

  @Prop({ default: false })
  private isReport?: boolean

  private async getModeActive() {
    const { data: modes } = await Vue.prototype.$http.httpWithToken.get(`/v3/student_class_modes`, {
      params: { is_student: 'TRUE', page: 1, limit: 200 },
    })
    this.$store.commit('listModeV3/setListMode', modes.class_modes)
    const classModeCode = this.$store.getters['drillsV3/classModeCode']
    const modeActive = modes.class_modes.find((o: any) => o.code === classModeCode)
    this.$store.commit('drillsV3/setModeActive', modeActive)
    return modeActive
  }

  private async mounted() {
    if (this.isReport) {
      //@ts-ignore
      this.title = this.$route.query?.title || ''
      return
    }
    Vue.prototype.$logger.info('-- Title V3 mounted')
    // タイトル取得APIを呼ぶ
    if (Vue.prototype.$cookies.get('authGdls').role == 'student') {
      let modeActive = await this.$store.getters['drillsV3/modeActive']
      if (!modeActive) {
        modeActive = await this.getModeActive()
      }
      this.classCategoryCode = this.$store.getters['drillsV3/classCategoryCode']
      this.is_random_question = modeActive?.is_random_question
    }
    try {
      const res = await Vue.prototype.$http.httpWithToken.get(
        `/v3/drills/title?resultDrillId=${Number(this.$route.query.resultDrillId)}`
      )
      this.title = res.data.title
      this.$store.commit('drillsV3/setTitleResult', res.data.title)
      if (this.classCategoryCode === 'SY') {
        const _title = Vue.prototype.$cookies.get(TITLE_HOMEWORK)
        if (_title) {
          this.title = _title + 'の宿題'
        }
      }
    } catch {
      throw 'Drill Error!'
    }
  }
}
