






















































import MyScript, { MyScriptStroke } from '@/components/MyScript'
import ButtonBase from '@/components/atoms/v3/ButtonBase.vue'
import MemoIcon from '@/components/icons/MemoIcon.vue'
import TabsButtonMath from '@/components/modules/drillsv3/molecules/TabsButtonMath.vue'
import TitleWithPenSwitcherMath from '@/components/modules/drillsv3/molecules/TitleWithPenSwitcherMath.vue'
import AnswerAreaV3Math from '@/components/modules/drillsv3/organisms/AnswerAreaV3Math.vue'
import QuestionImageHorizontalV3Math from '@/components/modules/drillsv3/organisms/QuestionImageHorizontalV3Math.vue'
import QuestionImageVertical from '@/components/modules/drillsv3/organisms/QuestionImageVertical.vue'
import TransitionMath from '@/components/modules/drillsv3/organisms/TransitionMath.vue'
import DrillMethodMath from '@/mixins/drillsv3/DrillMethodMath'
import DrillTransition from '@/mixins/drillsv3/DrillTransition'
import { ResponseProblem, ResponseStudyProblem, SProblem } from '@/mixins/drillsv3/WebApi'
import { isArray } from 'lodash'
import { Component, Mixins, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import { PEN_COLOR_KEY, LINE_WIDTH } from '@/constants'
import { StrokeType } from '@/types/canvas'
import RadioImaged from '@/components/atoms/RadioImaged.vue'
import { Debounce } from 'vue-debounce-decorator'

@Component({
  components: {
    QuestionImageVertical,
    TransitionMath,
    TitleWithPenSwitcherMath,
    AnswerAreaV3Math,
    QuestionImageHorizontalV3Math,
    TabsButtonMath,
    ButtonBase,
    MemoIcon,
    RadioImaged
  },
})
export default class MakeQuestionDrill extends Mixins(DrillTransition, DrillMethodMath) {
  @Ref() tabsButtonRef!: TabsButtonMath
  @Ref() answerAreaRef!: AnswerAreaV3Math
  @Ref() drillRef!: HTMLElement
  @Ref() titleSwitcherRef!: TitleWithPenSwitcherMath
  @Ref() questionImageRef!: QuestionImageHorizontalV3Math


  @Prop()
  problems!: ResponseProblem[]
  @Prop()
  studyProblem!: ResponseStudyProblem
  @Prop()
  subjectCode!: string
  @Prop()
  buttonCancelTransition!: { onClick: () => void; text?: string }
  @Prop()
  drillType!: boolean
  @Prop()
  studyType!: string
  private myScript = new MyScript({ lang: 'MATHML' })
  private tabsButton: any[] = []
  private currenTabButtonIndex = 0
  private arrHiddenColor = [PEN_COLOR_KEY.RED, PEN_COLOR_KEY.BLUE]
  private checkCanvasIsEmptyFollowQues: {
    [key: string]: {
      memo: boolean,
      answers: boolean
    }
  } = {}
  private heightQuestionImageExpand = 0
  private handleExpand() {
    this.currentQuestion.isExpandMode = !this.currentQuestion?.isExpandMode
  }

  private handleChangePenColor(colorCode: string) {
    if (!colorCode.length) {
      this.answerAreaRef.deactivateCanvas();
      this.questionImageRef.deactivateCanvas();
      return
    }
    this.answerAreaRef.activateCanvas()
    this.questionImageRef.activateCanvas()
    this.answerAreaRef.changePenType({ colorCode })
    this.questionImageRef.changePenType({ colorCode })
  }


  private undo(type: 'memo' | 'answer') {
    type === 'memo' ? this.questionImageRef.undo() : this.answerAreaRef.undo()
  }

  private redo(type: 'memo' | 'answer') {
    type === 'memo' ? this.questionImageRef.redo() : this.answerAreaRef.redo()
  }

  private handleDeleteMemoOrAnswer(type: 'memo' | 'answer') {
    if (type === 'memo') {
      this.questionImageRef.deleteCanvas()
      return;
    }
    this.answerAreaRef.deleteCanvas()
  }

  private wait(ms: number) {
    return new Promise((resolve: any) => {
      setTimeout(() => {
        resolve()
      }, ms)
    })
  }

  private async recognize(
    dataStorekes: StrokeType[][],
    type?: 'text',
    params?: {
      lang?: string
    }
  ) {
    await this.wait(500)

    if (dataStorekes?.length === 0) {
      return ''
    }

    const strokes: MyScriptStroke[] = dataStorekes.map((stroke: StrokeType[]) => ({
      x: stroke.map((t: StrokeType) => t.x),
      y: stroke.map((t: StrokeType) => t.y),
    }))

    this.myScript.setStrokes = await strokes
    this.myScript.setSize = await { width: window.innerWidth, height: window.innerHeight }

    // recognizeRawContent
    if (type === 'text') {
      return this.myScript.recognizeText({ lang: params?.lang })
    }
    return this.myScript.recognize()
  }


  private handleProblemToTabsButton(problems: SProblem[], currentPage: number) {
    let countQues = 0
    this.tabsButton = problems.reduce((newTabsButton: any[], item) => {
      const listQues = item.questions.map((question) => {
        countQues++
        return {
          id: question.questionCode,
          text: currentPage + '-' + countQues,
          problemId: item.sProblemId,
        }
      })

      return [...newTabsButton, ...listQues]
    }, [])
    this.tabsButtonRef.setCurrentTab(this.tabsButton[0].id)
  }

  private clearAllCanvas() {
    this.answerAreaRef.clearCanvas()
    this.questionImageRef.clearCanvas()
  }

  private async handleClickTab(
    item: { id: string | number; text: string },
    oldItemId: string | number,
    isLastQuestion?: boolean
  ) {

    this.currentCanvasData = {
      ...this.currentCanvasData,
      [oldItemId]: {
        memo: {
          ...this.questionImageRef.getDataStroke(),
          file: await this.questionImageRef.getImageFileCanvas(Date.now().toString()),
        },
        answer: {
          ...this.answerAreaRef.getDataStroke(),
          file: this.answerAreaRef.getImageFileCanvas(oldItemId.toString()),
        },
      }
    }

    this.checkCanvasIsEmptyFollowQues[oldItemId] = {
      memo: this.questionImageRef.checkCanvasIsEmpty(),
      answers: this.answerAreaRef.checkCanvasIsEmpty()
    }


    this.currenTabButtonIndex = this.tabsButton.findIndex((obj: any) => obj.id === item.id)
    this.currentQuestion = this.problemsInfo[item.id] as any
    this.clearAllCanvas()
    const currentCanvasInfo = this.currentCanvasData[this.currentQuestion.questionCode];
    this.answerAreaRef.setDataStroke(currentCanvasInfo.answer)
    this.questionImageRef.setDataStroke(currentCanvasInfo.memo)
    this.answerAreaRef.redraw(currentCanvasInfo.answer.undoStack);
    this.questionImageRef.redraw(currentCanvasInfo.memo.undoStack)
    this.tabsButtonRef.setCurrentTab(item.id)

  }

  private async processData() {
    const newData = {}

    const arrPromise = []
    const arrQuestionBatch = []

    for (const item of this.tabsButton) {
      const answerStrokes = this.currentCanvasData?.[item.id].answer.realStrokes;
      if (this.subjectCode === 'su' && answerStrokes && answerStrokes?.length) {
        arrPromise.push(
          Promise.all([
            this.recognize(answerStrokes),
            this.recognize(answerStrokes, 'text'),
          ])
        )
        arrQuestionBatch.push(item.id)
        continue
      }

      if (this.subjectCode !== 'su' && answerStrokes && answerStrokes?.length) {
        arrPromise.push(
          this.recognize(answerStrokes, 'text', {
            lang: this.subjectCode === 'ei' ? 'en_US' : undefined,
          })
        )
        arrQuestionBatch.push(item.id)
        continue
      }
    }

    const result = await Promise.all(arrPromise)

    arrQuestionBatch.forEach(async (questionCode, index) => {
      if (isArray(result[index])) {
        const [studentTextAnswer, candidatesText] = result[index]
        newData[questionCode] = {
          student_text_answer: studentTextAnswer,
          candidates: (candidatesText || '').replace(/\s/g, ''),
        }
      } else {
        newData[questionCode] = {
          student_text_answer: result[index],
        }
      }
    })

    return newData
  }

  private async handleSubmitAnswer() {
    this.currentCanvasData = {
      ...this.currentCanvasData,
      [this.currentQuestion.questionCode]: {
        memo: {
          ...this.questionImageRef.getDataStroke(),
          file: await this.questionImageRef.getImageFileCanvas(Date.now().toString()),
        },
        answer: {
          ...this.answerAreaRef.getDataStroke(),
          file: this.answerAreaRef.getImageFileCanvas(this.currentQuestion.questionCode.toString()),
        },
      }
    }

    this.checkCanvasIsEmptyFollowQues[this.currentQuestion.questionCode] = {
      memo: this.questionImageRef.checkCanvasIsEmpty(),
      answers: this.answerAreaRef.checkCanvasIsEmpty()
    }


    const keyQuesCode = Object.keys(this.currentCanvasData)

    const questionCodeEmpty = keyQuesCode.filter((questionCode) => this.checkCanvasIsEmptyFollowQues[questionCode]?.answers)

    const tabNameEmpty = this.tabsButton.reduce(
      (newStr, item) => (questionCodeEmpty.includes(item.id) ? [...newStr, item.text] : newStr),
      []
    )

    const tabNameNotSave: any = []

    let resultConfitm = true

    if (tabNameEmpty.length) {
      resultConfitm = confirm(
        `${[
          ...(tabNameEmpty.length ? [tabNameEmpty.join(',') + ' の未解答です'] : []),
          ...(tabNameNotSave.length ? [tabNameNotSave.join(',') + ' が未完了。'] : []),
        ].join(', ')}このまま回答を提供してもよろしいでしょうか。`
      )
    }

    if (!resultConfitm) {
      let indexTab = 0
      let isHaveValue = false
      this.tabsButton = this.tabsButton.map((item, index) => {
        if (questionCodeEmpty.includes(item.id) || (!questionCodeEmpty.includes(item.id) && item.color !== '#61d84e')) {
          if (!isHaveValue) {
            indexTab = index
            isHaveValue = true
          }
          return { ...item, color: '#ffe167', colorActive: '#fbca06' }
        }
        return item
      })

      const isLastTab = indexTab === this.tabsButton.length - 1

      this.handleClickTab(this.tabsButton[indexTab], (this.tabsButton[this.currenTabButtonIndex] as any).id, isLastTab)
      return
    }

    Vue.prototype.$loading.start()

    const dataMyScript = await this.processData()

    await this.handleSaveManyMemo()

    const result = await this.saveAllAnswer({
      page_num: this.$route.query.page as string,
      result_drill_id: this.$route.query.resultDrillId as string,
      ocr_data: this.tabsButton.map((item) => ({
        question_code: item.id,
        image: this.currentCanvasData?.[item.id].answer.file || null,
        number: this.problemsInfo[item.id].number.toString(),
        question_answer: this.problemsInfo[item.id].question_answer,
        done: !!this.currentCanvasData?.[item.id].answer.file && !questionCodeEmpty.includes(item.id),
        student_text_answer: dataMyScript?.[item.id]?.student_text_answer || '',
        candidates: dataMyScript?.[item.id]?.candidates || '',
      })) as any,
    })


    if (result?.status === 201) {
      this.$router.push({
        path:
          this.urlScoringMath +
          (this.drillType ? `&questionCode=${this.$route.params.questionCode}&drillType=${this.drillType}` : '') +
          (this.studyType ? `&studyType=${this.studyType}` : ''),
      })
    }

    Vue.prototype.$loading.complete()
  }


  private async handleSaveManyMemo() {
    Vue.prototype.$loading.start()
    const _this = this;


    const questionsCodeNotEmpty = Object.keys(this.currentCanvasData).filter(questionCode => _this.checkCanvasIsEmptyFollowQues[questionCode]?.memo === false);

    await Promise.all(questionsCodeNotEmpty.map((questionCode) => this.saveMemo({
      page_num: this.$route.query.page as string,
      result_drill_id: this.$route.query.resultDrillId as string,
      question_code: questionCode,
      memo_img: _this.currentCanvasData[questionCode].memo.file as any,
      memo_coordinates: ""
    })))


    Vue.prototype.$loading.complete()
  }


  private handleDrawImageWhenResize(urlImg: string, widthContent: number) {
    const img = new Image()
    const canvas = this.answerAreaRef.getCurrentCanvas()
    img.src = urlImg

    img.onload = function () {
      const ratio = img.width / img.height
      canvas?.context.drawImage(img, 0, 0, widthContent, widthContent / ratio)
    }
  }

  private async handleChangeStatusBookmark(onUpdateStatusBookmark: (value: boolean) => void) {

    Vue.prototype.$loading.start()
    await Vue.prototype.$http.httpWithToken.post(`/v3/history/question_bookmarks`, {
      question_code: this.$route.params.questionCode,
      curriculum_s_unit_id: this.$route.query?.curriculumSUnitId,
      result_drill_id: this.$route.query.resultDrillId,
    })
    Vue.prototype.$loading.complete()
    onUpdateStatusBookmark(true)

    alert('問題をブックマークを保存しました。')

  }

  @Watch('problems', { deep: true })
  async onProblemsChanged(newProblems: ResponseProblem[]) {
    if (newProblems.length && !!this.studyProblem) {
      this.handleProblemToTabsButton(newProblems[0]?.problems, this.studyProblem.currentPage)

      const [dataImage, sijiUrl, dataImageD] = await Promise.all([
        this.handleGetImage(newProblems[0]?.problems),
        this.handleGetImageSiji(newProblems[0]?.problems),
        this.handleGetImageD(newProblems[0]?.problems),
      ])

      this.sijiUrl = sijiUrl

      const result = this.handleFormatDataProblem(newProblems[0]?.problems, dataImage, this.studyProblem.currentPage, {
        dataImageD,
      })

      this.problemsInfo = result

      this.currentQuestion = Object.values(result)?.[0] as any

      this.handleCalculatorHeightQuestionImage()
    }
  }

  private get buttonSubmitProps() {
    return {
      class: 'ml_submit-auto',
      onClick: this.handleSubmitAnswer,
    }
  }

  private get bornTextButtonSubmit() {
    if (this.currenTabButtonIndex === this.tabsButton.length - 1) return { text: '正定誤判' }
    return { text: '完了' }
  }


  protected async mounted() {
    this.titleSwitcherRef.onSetActiveColor(PEN_COLOR_KEY.BLACK)
    this.answerAreaRef.activateCanvas()
    this.questionImageRef.activateCanvas()
    window.addEventListener('resize', this.handleResize)
  }

  private beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  }

  private handleChangeLineWidth(type: 'thin' | 'medium' | 'advand') {
    const lineWidth = type === 'thin' ? LINE_WIDTH.THIN : type === 'medium' ? LINE_WIDTH.MEDIUM : LINE_WIDTH.ADVAND;
    this.answerAreaRef.changeLineWidth(lineWidth)
    this.questionImageRef.changeLineWidth(lineWidth)
  }

  private handleChangeHandStatus(oldStatusHand: boolean) {
    if (oldStatusHand) {
      this.handleChangePenColor(PEN_COLOR_KEY.BLACK)
      this.titleSwitcherRef.onSetActiveColor(PEN_COLOR_KEY.BLACK)
    }
  }

  private handleChangeEraseStatus(oldStatusErase: boolean) {
    if (oldStatusErase) {
      this.answerAreaRef.deactivateCanvas()
      this.questionImageRef.deactivateCanvas()
      this.handleChangePenColor(PEN_COLOR_KEY.BLACK)
      this.titleSwitcherRef.onSetActiveColor(PEN_COLOR_KEY.BLACK)
      return;
    }

    this.questionImageRef.activateCanvas()
    this.questionImageRef.setEraser();
    this.answerAreaRef.activateCanvas()
    this.answerAreaRef.setEraser();
  }

  private get getHeightQUestionImage() {
    return this.currentQuestion.isExpandMode ? `${this.heightQuestionImageExpand}px` : ''
  }

  private handleCalculatorHeightQuestionImage() {
    const HEIGHT_TRANSITION = 70
    const TOTLA_SPACE = 15 * 3 + 15
    const height = window.innerHeight * 0.9 - (document.querySelector('.student-headermenu-layout__header')?.clientHeight || 0) - this.titleSwitcherRef.$el.clientHeight - this.tabsButtonRef.$el.clientHeight - HEIGHT_TRANSITION - TOTLA_SPACE;

    this.heightQuestionImageExpand = height
  }

  @Debounce(300)
  private handleResize() {
    this.handleCalculatorHeightQuestionImage()
  }
}
