










import ButtonBase from '@/components/atoms/ButtonBase.vue'
import PenCanvasMath from '@/components/modules/drills/atoms/PenCanvasMath.vue'
import DrillPen from '@/components/organisms/study/DrillPen.vue'
import DrillPenAi from '@/components/organisms/study/DrillPenAi.vue'
import { Debounce } from 'vue-debounce-decorator'
import { Component, Prop, Vue, Mixins } from 'vue-property-decorator'
import { StrokeType } from '@/types/canvas'
import CanvasMethod from '@/mixins/v3/canvas'

@Component({
  components: {
    ButtonBase,
    PenCanvasMath,
    DrillPen,
    DrillPenAi,
  },
})
export default class AnswerAreaV3 extends Mixins(CanvasMethod) {
  @Prop()
  onSubmit?: () => void

  @Prop()
  onChangeDrawedImage?: (imgUrl: string, width: number) => void

  @Prop({ default: { text: '' } })
  buttonSubmitProp?: { text: string }

  @Prop()
  onDragCanvasEnd?: Function

  @Prop()
  onDragCanvasStart?: Function

  private handleResize = this.changeCanvasSize

  private created() {
    this.canvasName = 'canvasNameAnswerQuestion';
  }

  private mounted() {
    Vue.prototype.$logger.info('-- AnswerAreaV3 V3 mounted')
    this.handleResize(true)
    window.addEventListener('resize', () => this.handleResize(false))
  }

  private beforeDestroy() {
    window.removeEventListener('resize', () => this.handleResize(false))
  }

  private handleClickNexPageOrSubmit() {
    this.onSubmit?.()
  }

  private handleDragEndCanvas(stroke: StrokeType[]) {
    this.onDragCanvasEnd?.(stroke)
  }

  @Debounce(300)
  private changeCanvasSize(isMount?: boolean): void {
    const leftBlock = this.$refs['containerRef'] as HTMLElement
    // canvasサイズ設定
    const { width, height } = this.calculateCanvasSize(leftBlock)

    const oldWidthHeight = Vue.prototype.$penCanvases[this.canvasName].getWidthHeight()

    const oldStrokes = Vue.prototype.$penCanvases[this.canvasName].getStroke()

    const scaleX = width / oldWidthHeight.width
    const strokes = oldStrokes.map((stroke: any) =>
      stroke.map((point: any) => ({
        ...point,
        x: point.x * scaleX,
        y: point.y * 1,
      }))
    )

    this.setCanvasSize(Vue.prototype.$penCanvases[this.canvasName], { width, height })

    setTimeout(() => {
      Vue.prototype.$penCanvases[this.canvasName].redraw(strokes)
    })
  }

  private calculateCanvasSize(element: HTMLElement): any {
    if (!element) return { width: 0, height: 0 }
    return { width: element.clientWidth, height: element.scrollHeight }
  }

  private handleDragStartCanvas() {
    this.onDragCanvasStart?.()
  }

}
